<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title flexcb">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">首页</a>
        </span>
        <div class="flexcb" v-if="msgList.length">
          <el-carousel
            height="25px"
            style="width:400px"
            direction="vertical"
            :autoplay="true"
            indicator-position="none"
          >
            <el-carousel-item
              v-for="item in msgList"
              :key="item"
              style="width:100%"
            >
              <div
                @click="domsgInfo(item.noticeId)"
                style="width:100%;height:25px;line-height:25px;font-size:14px;cursor: pointer;"
                class="medium flexcb"
              >
                <div class="flexca">
                  <span style="min-width:100px;text-align:right"
                    >【{{
                      $setDictionary("NOTICETYPE", item.noticeType)
                    }}】</span
                  >

                  <el-tooltip
                    effect="dark"
                    :content="item.noticeTitle"
                    placement="bottom"
                  >
                    <span
                      style="max-width:240px;white-space:nowrap;overflow:hidden;"
                    >
                      {{ item.noticeTitle }}
                    </span>
                  </el-tooltip>
                </div>

                <span style="min-width:120px;text-align:right">{{
                  item.noticeTopTime | momentDate
                }}</span>
              </div>
            </el-carousel-item>
          </el-carousel>
          <span
            class="cur-a"
            style="margin-left:10px;cursor: pointer;"
            @click="lookMore"
            >更多>></span
          >
        </div>
      </div>
      <div class="framePage-body" style="background:#f5f5f6">
        <div class="bodytop">
          <div class="operationControl flexdc agentHome">
            <h3 class="title" style="padding:0 1rem">业绩概况</h3>
            <div class="searchbox">
              <div class="searchboxItem ci-full">
                <el-date-picker
                  v-model="agentStartTime"
                  size="small"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  unlink-panels
                  :picker-options="pickerOptions"
                />
              </div>
              <div title="代理区域" class="searchboxItem ci-full">
                <el-cascader
                  v-model="areaId"
                  placeholder="请选择代理区域"
                  :options="areatreeList"
                  :props="propsarea"
                  size="small"
                  clearable
                  filterable
                  @change="handleChange"
                ></el-cascader>
              </div>
              <div title="代理类型" class="searchboxItem ci-full">
                <el-cascader
                  placeholder="请选择代理培训类型"
                  v-model="trainTypeId"
                  :options="trainTypeList"
                  :props="propsTrainType"
                  clearable
                  size="small"
                ></el-cascader>
              </div>
              <div class="btnBox" style="margin-left:20px">
                <el-button
                  style="margin-left:20px"
                  class="bgc-bv"
                  round
                  @click="getTotal()"
                  >查询</el-button
                >
              </div>
            </div>
          </div>
          <div class="agentHomeBox">
            <div class="cellbox">
              <div class="cell">
                <div
                  style="display:flex;padding:18px 36px 20px 36px;flex-direction: column;"
                >
                  <div
                    style="border-bottom:1px solid #E9E9E9;display: flex;flex: 1;padding-bottom: .5rem;align-items: center;"
                  >
                    <img src="../../assets/export.png" alt="" />
                    <p style="padding-left:11px">导入学员</p>
                  </div>
                  <div class="personCount">
                    <div>
                      <p class="countNum">{{ todayImportNum }}人</p>
                      <p class="countTitle">今日</p>
                    </div>
                    <div>
                      <p class="countNum">{{ totalImportNum }}人</p>
                      <p class="countTitle">总计</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cellbox">
              <div class="cell">
                <div
                  style="display:flex;padding:18px 36px 20px 36px;flex-direction: column;"
                >
                  <div
                    style="border-bottom:1px solid #E9E9E9;display: flex;flex: 1;padding-bottom: .5rem;align-items: center;"
                  >
                    <img src="../../assets/export1.png" alt="" />
                    <p style="padding-left:11px">入班学员</p>
                  </div>
                  <div class="personCount">
                    <div>
                      <p class="countNum">{{ todayJoinClassNum }}人</p>
                      <p class="countTitle">今日</p>
                    </div>
                    <div>
                      <p class="countNum">{{ totalJoinClassNum }}人</p>
                      <p class="countTitle">总计</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cellbox">
              <div class="cell">
                <div
                  style="display:flex;padding:18px 36px 20px 36px;flex-direction: column;"
                >
                  <div
                    style="border-bottom:1px solid #E9E9E9;display: flex;flex: 1;padding-bottom: .5rem;align-items: center;"
                  >
                    <img src="../../assets/export2.png" alt="" />
                    <p style="padding-left:11px">结业学员</p>
                  </div>
                  <div class="personCount">
                    <div>
                      <p class="countNum">{{ todayCompleteNum }}人</p>
                      <p class="countTitle">今日</p>
                    </div>
                    <div>
                      <p class="countNum">{{ totalCompleteNum }}人</p>
                      <p class="countTitle">总计</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bodybottom">
          <div class="form-box flexdc" style="height:100%">
            <div class="flexdc" style="height:100%">
              <div class="echerts-box flexdc" style="display:flex;height:100%">
                <div
                  class="btn-box"
                  style="display:flex;padding: 1rem;align-items: center;"
                >
                  <h3 class="title" style="padding:0 1rem">业绩分析</h3>
                  <div style="padding-right:1rem">
                    <el-date-picker
                      v-model="AgentEndTime"
                      size="small"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      unlink-panels
                      :picker-options="pickerOptions"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      @change="AgentEndTimechange"
                    />
                  </div>

                  <el-button
                    size="mini"
                    :class="this.stu == 'mechanism' ? 'bgc-bv' : 'active'"
                    @click="getCompanyEchart('mechanism')"
                    >导入学员</el-button
                  >
                  <el-button
                    size="mini"
                    :class="this.stu == 'company' ? 'bgc-bv' : 'active'"
                    @click="getCompanyEchart('company')"
                    >入班学员</el-button
                  >
                  <el-button
                    size="mini"
                    :class="this.stu == 'complete' ? 'bgc-bv' : 'active'"
                    @click="getCompanyEchart('complete')"
                    >结业学员</el-button
                  >
                </div>
                <div
                  class="echarts flex1"
                  id="echart1"
                  ref="echart1"
                  style="height:100%"
                  v-show="echartsShow"
                ></div>
                <div
                  style=" height:100%; flex:1;position:relative"
                  v-show="trues"
                >
                  <div style="height:100%; display:flex;">
                    <img
                      src="@/assets/empty.png"
                      alt=""
                      style="width:300px; height:185px"
                      class="imgCenter"
                    />
                    <span class="span">暂无数据</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
let echart1 = {};
export default {
  name: "operate/accountList",
  components: {},
  data() {
    return {
      msgList: [],
      agentStartTime: "",
      AgentEndTime: "",
      areatreeList: [],
      trainTypeList: [],
      propsarea: {
        value: "value",
        label: "label",
        disabled: "disabled",
        emitPath: false,
        checkStrictly: true,
      },
      propsTrainType: {
        value: "id",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      trues: false,
      echartsShow: true,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      totalImportNum: "0",
      todayImportNum: "0",
      totalJoinClassNum: "0",
      todayJoinClassNum: "0",
      totalCompleteNum: "0",
      todayCompleteNum: "0",
      stu: "mechanism",
      treeList: [],
      ColorTypeList: [],
      arrName: [],
      trainTypeIdRange: [],
    };
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  created() {
    if (this.userJson.trainTypeIdRange) {
      this.trainTypeIdRange = this.userJson.trainTypeIdRange.split(",");
    }
    let createTime = this.userJson.createTime
      .split(" ")[0]
      .replaceAll("/", "-");
    var aData = new Date();
      let year = aData.getFullYear();
      let month = aData.getMonth() + 1;
      let day = aData.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
       this.value = year + "-" + month + "-" + day;
    // this.agentStartTime = [createTime, this.value];
    this.getnew();
    this.getareatree();
    this.getTrainTypeList();
  },
  mounted() {
    // this.initEchart();
    this.getTree();
    this.getTotal();
    this.getColorList();
    this.getCompanyEchart();
    echart1 = this.$echarts.init(document.getElementById("echart1"));
  },
  methods: {
    getColorList() {
      const ColorList = this.$setDictionary("TRAIN_TYPE_COLOR", "list");
      const list = [];
      for (const key in ColorList) {
        list.push({
          value: key,
          label: ColorList[key],
        });
      }
      this.ColorTypeList = list;
    },
    // 消息
    getnew() {
      const parmas = {
        pageNum: "1",
        pageSize: "3",
      };
      this.$post("/notice/querySysNoticeCompList", parmas).then((res) => {
        if (res.status == "0") {
          this.msgList = res.data.list || [];
        }
      });
    },
    domsgInfo(noticeId) {
      this.$router.push({
        path: "/web/noticationDetail",
        query: {
          noticeId,
        },
      });
    },
    lookMore() {
      this.$router.push("/web/systemNotication");
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        let data = ret.data || [];
        this.areatreeList = data;
      });
    },
    getTotal() {
      let parmar = {};
      if (this.agentStartTime) {
        parmar.startDate = this.agentStartTime[0];
        parmar.endDate = this.agentStartTime[1];
      }
      if (this.areaId) {
        parmar.areaId = this.areaId;
      }
      if (this.trainTypeId) {
        parmar.trainTypeId = this.trainTypeId;
      }
      this.$post("/agency/home/overview", parmar)
        .then((ret) => {
          this.totalImportNum = ret.data.totalImportNum;
          this.todayImportNum = ret.data.todayImportNum;
          this.totalJoinClassNum = ret.data.totalJoinClassNum;
          this.todayJoinClassNum = ret.data.todayJoinClassNum;
          this.totalCompleteNum = ret.data.totalCompleteNum;
          this.todayCompleteNum = ret.data.todayCompleteNum;
        })
        .catch((err) => {
          return;
        });
    },
    /**
     * tree接口
     */
    getTrainTypeList() {
      // this.$post("/agency/ma-agency-student/getAreaAndTrain")
      //   .then((res) => {
      //     this.trainTypeList = res.data.trainTypeList;
      //   })
      //   .catch(() => {
      //     return;
      //   });
         this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    AgentEndTimechange(val) {
      this.getCompanyEchart("mechanism");
    },
    getTree() {
      this.$post("/sys/category/train-type/root")
        .then((ret) => {
          this.treeList = ret.data || [];
        })
        .catch((err) => {});
    },
    // 企业图表
    getCompanyEchart(stu = "mechanism") {
      this.stu = stu || "mechanism";
      let params = {};
      if (this.AgentEndTime) {
        params.startDate = this.AgentEndTime[0];
        params.endDate = this.AgentEndTime[1];
      }
      this.$post("/agency/home/analyse", params).then((res) => {
        this.AgentEndTime = [
          res.data.dateArr[0],
          res.data.dateArr[res.data.dateArr.length - 1],
        ];
        if (
          res.data.dateArr.length != 0 ||
          res.data.importMap.length != 0 ||
          res.data.joinMap.length != 0
        ) {
          this.initEchart(res.data, this.stu);
          this.trues = false;
          this.echartsShow = true;
        } else {
          this.trues = true;
          this.echartsShow = false;
          document
            .getElementById("echart1")
            .removeAttribute("_echarts_instance_");
        }
      });
    },
    initEchart(params, stu) {
      const that = this;
      let arr = [];
      let arrColor = [];
      let series = [];
      that.arrName = []
      if (stu == "mechanism") {
         that.arrName = []
        for (let i in params.importMap) {
            that.arrName.push('导入数量');
            arrColor.push('#366edb');
          // that.treeList.map((el, index) => {
          //   if (el.trainTypeId == i) {
            
          //   }
          // });
          // that.ColorTypeList.map((el, index) => {
          //   if (el.value == i) {
          //     arrColor.push(el.label);
          //   }
          // });
          arr.push(params.importMap[i]); //属性
        }
      } else if(stu == "company") {
        that.arrName = []
        for (let i in params.joinMap) {
          that.treeList.map((el, index) => {
            if (el.trainTypeId == i) {
              that.arrName.push(el.trainTypeName);
            }
          });
          that.ColorTypeList.map((el, index) => {
            if (el.value == i) {
              arrColor.push(el.label);
            }
          });
          arr.push(params.joinMap[i]); //属性
        }
      } else {
          that.arrName = []
        for (let i in params.graduationMap) {
          that.treeList.map((el, index) => {
            if (el.trainTypeId == i) {
              that.arrName.push(el.trainTypeName);
            }
          });
          that.ColorTypeList.map((el, index) => {
            if (el.value == i) {
              arrColor.push(el.label);
            }
          });
          arr.push(params.graduationMap[i]); //属性
        }
      }
      arr.map((el, index) => {
        series.push({
          name: that.arrName[index],
          type: "line",
          smooth: true,
          itemStyle: {
            normal: {
              areaStyle: {
                type: "default",
              },
            },
          },
          data: arr[index],
        });
      });
      let option = {
        color: arrColor,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          orient: "horizontal",
          x: "right",
          padding: [0, 60],
          itemWidth: 15,
          itemHeight: 10,
          itemGap: 8,
          textStyle: {
            color: "#484848",
            fontSize: 14, // 图例文字颜色
          },
          icon: "stack",
          data: that.arrName,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "15%",
          top: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: params.dateArr,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval:
                params.dateArr.length > 20
                  ? 10
                  : params.dateArr.length <= 8
                  ? 0
                  : 4,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: series,
      };
      echart1.setOption(option,true);
      this.$forceUpdate()
      // window.resize(function() {
      //   echart1.resize();
      // });
      window.addEventListener("resize", function() {
        echart1.resize();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bodytop {
  background: #fff;
}
.agentHome {
  padding: 1rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
}
.agentHomeBox {
  display: flex;
  .cellbox {
    width: 33.3%;
    background: #fff;
    padding: 1rem;
    .cell {
      background: #fff;
      box-shadow: 0px 1px 21px 0px rgba(38, 46, 45, 0.06);
    }
  }
}
.personCount {
  display: flex;
  padding-top: 1.2rem;
  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .countNum {
    font-size: 1.4rem;
    font-weight: 400;
    color: #333;
  }
  .countTitle {
    font-size: 0.75rem;
    color: #666;
    font-weight: 400;
  }
}
.bodybottom {
  background: #fff;
  margin-top: 1.15rem;
  height: 100%;
}
</style>
